// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap')
require("jquery")
require('glightbox');
require('!mapbox-gl');
require('select2');
require('typewriter-effect/dist/core');
require('cookieconsent')
require('jquery-autocompleter/dist/jquery.autocompleter.js');

require('../stylesheets/application.scss') //Import your application.scss here

const images = require.context('../images', true)

global.$ = jQuery;
global.GLightbox = GLightbox;
global.mapboxgl = mapboxgl;
global.Typewriter = Typewriter;
global.cookieconsent = cookieconsent;

// https://www.vic-l.com/setup-bootstrap-in-rails-6-with-webpacker-for-development-and-production

